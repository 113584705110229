.shadowdetect-fake-table {
    @apply w-full glass-effect rounded;
}

.shadowdetect-fake-table .row {
    @apply flex flex-row justify-between border-b p-4 border-olive-200 dark:border-olive-800
}

.shadowdetect-fake-table .row:last-child {
    @apply border-none
}

.table-header{
    @apply text-xs text-left uppercase border-b border-olive-200 dark:border-olive-800 font-normal
}

.table-header-cell {
    @apply px-4 py-3 border light-text text-left border-olive-200 dark:border-olive-800
}
.table-row-cell {
    @apply px-4 py-3 border border-olive-200 dark:border-olive-800
}