[data-theme="light"] {
    .gradient-dark-bg {
        @apply bg-olive-950;
        // background-image:
        //     radial-gradient(at 51% 26%, hsla(197, 65%, 10%, 1) 0px, transparent 50%),
        //     radial-gradient(at 17% 93%, oklch(19.14% 0.041 260) 0px, transparent 50%),
        //     radial-gradient(at 100% 100%, oklch(92.99% 0.042 300) 0px, transparent 50%),
        //     radial-gradient(at 2% 19%, oklch(19.14% 0.041 260) 0px, transparent 50%);
    }

    .gradient-light-bg {
        // background: #f1f1f1;
        // background: linear-gradient(58deg, #ffd1af 0%, #d6f2f5 15%, #f1f1f1 40%)

        @apply bg-olive-50;
        background-image:
            radial-gradient(at 51% 26%, oklch(96.48% 0.017 260) 0px, transparent 50%),
            radial-gradient(at 21% 1%, oklch(91.8% 0.042 260) 0px, transparent 50%),
            // radial-gradient(at 17% 93%, oklch(92.38% 0.042 340) 0px, transparent 50%),
            radial-gradient(at 100% 100%, oklch(92.99% 0.042 300) 0px, transparent 50%),

    }
}

[data-theme="dark"] {
    .gradient-dark-bg {
        @apply bg-olive-50;
        background-image:
            radial-gradient(at 51% 26%, oklch(96.48% 0.017 260) 0px, transparent 50%),
            radial-gradient(at 21% 1%, oklch(91.8% 0.042 260) 0px, transparent 50%),
            //radial-gradient(at 17% 93%, oklch(92.38% 0.042 240) 0px, transparent 50%),
            radial-gradient(at 100% 100%, oklch(92.99% 0.042 300) 0px, transparent 50%),

    }

    .gradient-light-bg {
        @apply bg-olive-950;
        // background-image:
        //     radial-gradient(at 51% 26%, hsla(197, 65%, 10%, 1) 0px, transparent 50%),
        //     radial-gradient(at 17% 93%, oklch(19.14% 0.041 260) 0px, transparent 50%),
        //     radial-gradient(at 100% 100%, oklch(92.99% 0.042 300) 0px, transparent 50%),
        //     radial-gradient(at 2% 19%, oklch(19.14% 0.041 260) 0px, transparent 50%);
    }
}

.gradient-login-bg {
    @apply bg-olive-50;
    background-image:
        radial-gradient(at 51% 26%, hsla(179, 0%, 94%, 1) 0px, transparent 50%),
        radial-gradient(at 21% 1%, hsla(200, 69%, 87%, 0.84) 0px, transparent 50%),
        radial-gradient(at 17% 93%, hsla(24, 100%, 84%, 0.37) 0px, transparent 50%),
        radial-gradient(at 100% 100%, hsla(240, 80%, 61%, 0.35) 0px, transparent 50%),
        radial-gradient(at 2% 19%, hsla(354, 78%, 70%, 0.2) 0px, transparent 50%);
}

.gradient-logo {
    @apply text-olive-50;

    svg {
        width: 100%;
        height: auto;
    }
}

.gradient-logo-animated {
    @apply text-olive-50;
    animation: gradientlogoanimate 47s infinite;

    svg {
        width: 100%;
        height: auto;
    }
}

.glass-effect {
    @apply shadow;
    @apply bg-olive-50/30 dark:bg-olive-900/30;
    // backdrop-filter: blur(20px);
}

.glass-effect-no-blur {
    @apply shadow;
    @apply bg-olive-50/30 dark:bg-olive-900/30;
}

@keyframes gradientlogoanimate {

    /*
    Blue cae7f6
    Purple 704fec
    Pink ef7a85
    Orange ffd1af
*/

    0% {
        @apply text-red-500
    }

    25% {
        @apply text-teal-500
    }

    50% {
        @apply text-cyan-500
    }

    75% {
        @apply text-amber-500
    }

    100% {
        @apply text-red-500
    }
}