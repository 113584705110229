// all the openai stuff is contained in an .openai block
.openai{
    .markdown {
        h1, h2, h3, h4, h5, h6 {
            @apply mt-4 mb-2;
        }
        p {
            @apply mt-4 mb-4;
        }
        ol {
            @apply mt-4 mb-2 pl-5 list-decimal;
        }
        ul {
            @apply mt-4 mb-2 pl-5 list-disc;
        }
        li {
            @apply mt-4 mb-2;
        }
    }
}