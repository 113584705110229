.modal {
    @apply fixed md:py-5 top-0 right-0 left-0 bottom-0 z-50 w-full flex justify-center items-center flex-col p-2;
    margin-top: 0 !important; 
    padding-top: max(env(safe-area-inset-top), 2.5rem);
    padding-bottom: max(env(safe-area-inset-bottom), 2.5rem);
}
.message-modal {
    @apply min-h-screen absolute top-0 right-0 left-0 z-50 w-full flex justify-center items-center flex-col;
    margin-top: 0 !important;
}

.modal-content {
    @apply relative p-4 w-full max-w-2xl rounded glass-effect dark:bg-olive-950 z-50 flex grow flex-col;
    // min-height: calc(100vh - 2.5rem); 
    max-height: calc(100vh - 1rem);
    overflow-y: auto;
  
}
.message-modal-content {
    @apply relative p-4 w-full max-w-2xl h-full md:h-auto glass-effect rounded shadow dark:bg-olive-950 z-50;
    min-height: 100vh; 
}
.modal-fill {
    @apply fixed top-0 w-full min-h-screen z-10;
    background-color: #f0f0f0;
    background-image: radial-gradient(at 51% 26%, hsl(179, 0%, 94%) 0px, transparent 50%), radial-gradient(at 21% 1%, hsla(200, 69%, 87%, 0.84) 0px, transparent 50%), radial-gradient(at 17% 93%, hsla(24, 100%, 84%, 0.37) 0px, transparent 50%), radial-gradient(at 100% 100%, hsla(240, 80%, 61%, 0.35) 0px, transparent 50%), radial-gradient(at 2% 19%, hsla(354, 78%, 70%, 0.2) 0px, transparent 50%);
    backdrop-filter: blur(20px);
}
.modal-header {
    @apply flex justify-between items-start rounded-t border-b dark:border-gray-600;
}

.modal-body {
    @apply rounded space-y-4 text-center opposite-text font-medium transition duration-150 ease-in-out flex flex-col grow;
}

.modal-footer {
    @apply w-full rounded-b py-5;
}

.slideout-modal {
    @apply modal
}

.slideout-modal-content {
    @apply modal-content;
}

.slideout-modal-header {  
    @apply modal-header;
}
.slideout-modal-header h3{
    @apply mt-5;
}
.slideout-modal-header i{
    @apply mt-5;
}

.slideout-modal-body {
    @apply w-full flex flex-col justify-start grow;
}

.slideout-modal-footer {
    @apply flex justify-between items-start space-x-4 flex-row;
}

/*
.slideout-modal {
    @apply min-h-screen absolute top-0 right-0 left-0 bottom-0 z-50 w-full flex justify-items-center items-end flex-col;
    margin-top: 0 !important;
    padding-top: max(env(safe-area-inset-top), 2.5rem);
    padding-bottom: max(env(safe-area-inset-bottom), 2.5rem);
}

.slideout-modal-content {
    @apply min-h-screen relative w-full max-w-2xl h-full md:h-auto flex flex-col justify-between bg-olive-100 dark:bg-olive-800 z-50 flex grow flex-col;
}

.slideout-modal-header {  
    @apply flex flex-row justify-between transition duration-150 ease-in-out px-5 pb-5 glass-effect ;
}
.slideout-modal-header h3{
    @apply mt-5;
}
.slideout-modal-header i{
    @apply mt-5;
}

.slideout-modal-body {
    @apply w-full flex flex-col justify-start grow transition duration-150 ease-in-out p-5;
}

.slideout-modal-footer {
    @apply flex justify-between items-start space-x-4 flex-row transition duration-150 ease-in-out p-5 glass-effect;
}
*/
