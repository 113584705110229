.dropdown:hover .dropdown-menu {
    display: block;
}

.tab-content {
    display: none;
}

.tab-content.active {
    display: block;
}

.has-tooltip:hover .has-tooltip-hover\:flex {
    display: flex;
}