html {
    overflow-x: hidden;
    scrollbar-gutter: stable;
}

body {
    overflow-x:hidden;
}

*[data-theme="dark"] {
    scrollbar-width: thin;
    scrollbar-color: rgb(32, 33, 36) rgb(190, 198, 233);
}
*[data-theme="dark"]::-webkit-scrollbar{
    width:6px; 
}
*[data-theme="dark"]::-webkit-scrollbar-track{
    background: rgb(32, 33, 36);
}
*[data-theme="dark"]::-webkit-scrollbar-thumb{
    background-color:rgb(32, 33, 36);
    border: 1px solid transparent;
}


*[data-theme="light"] {
    scrollbar-width: thin;
    scrollbar-color: rgb(32, 33, 36) #eee;
}
*[data-theme="light"]::-webkit-scrollbar{
    width:6px; 
}
*[data-theme="light"]::-webkit-scrollbar-track{
    background: #eee;
}
*[data-theme="light"]::-webkit-scrollbar-thumb{
    background-color:rgb(32, 33, 36);
}