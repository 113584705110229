h1, h2, h3, h4, h5, h6 {
    font-family: 'Manrope', sans-serif;
    font-weight: 900;
    font-style: normal;
}

h1 em, h2 em, h3 em, h4 em, h5 em, h6 em {
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-style: italic;
}

h1 {
    @apply text-2xl font-black capitalize standard-text;
}

h2 {
    @apply text-xl capitalize standard-text
}

h3 {
    @apply text-xl font-semibold standard-text capitalize
}

h4 {
    @apply text-lg capitalize
}

h5 {
    @apply text-lg capitalize;

}

h6 {
    @apply text-base capitalize;

}

.word-break{
 word-break: break-word;
}

.shadowdetect-devicebox .text-label{
    @apply hidden sm:block sm:basis-1/3 light-text
}

.shadowdetect-devicebox .text-value {
    @apply basis-full sm:basis-2/3 standard-text text-left sm:text-right capitalize;
    word-break: break-word;
}
.shadowdetect-devicebox .text-value-lowercase {
    @apply basis-full sm:basis-2/3 standard-text text-left sm:text-right lowercase;
    word-break: break-word;
}


.shadowdetect-whitebox .text-label{
    @apply hidden sm:block sm:basis-1/3  light-text
}

.shadowdetect-whitebox .text-value {
    @apply basis-full sm:basis-2/3 standard-text text-left sm:text-right capitalize;
    word-break: break-word;
}
.shadowdetect-whitebox .text-value-lowercase {
    @apply basis-full sm:basis-2/3 standard-text text-left sm:text-right lowercase;
    word-break: break-word;
}


.shadowdetect-fake-table .text-label {
    @apply self-start light-text
}

.shadowdetect-fake-table .text-value {
    @apply self-end standard-text text-right capitalize
}
.shadowdetect-fake-table .text-value-lowercase {
    @apply self-end standard-text text-right lowercase
}

.shadowdetect-vulnlist .text-label{
    @apply light-text
}

.shadowdetect-vulnlist .text-value {
    @apply standard-text capitalize;
    word-break: break-word;
}
.shadowdetect-vulnlist .text-value-lowercase {
    @apply standard-text lowercase;
    word-break: break-word;
}


.shadowdetect-msplist .text-label{
    @apply light-text
}
.shadowdetect-msplist .text-value {
    @apply standard-text capitalize;
    // word-break: break-word;
}
.shadowdetect-msplist .text-value-lowercase {
    @apply standard-text lowercase;
    // word-break: break-word;
}

.shadowdetect-userlist .text-label{
    @apply light-text
}
.shadowdetect-userlist .text-value {
    @apply standard-text capitalize;
    // word-break: break-word;
}
.shadowdetect-userlist .text-value-lowercase {
    @apply standard-text lowercase;
    // word-break: break-word;
}

i.dashboard-device-overview-icon svg {
    width: 100%;
    height: 100%;
}

i.dashboard-device-overview-icon {
    width: 1rem;
    height: 1rem;
    display: block;
}
i.dashboard-device-overview-icon-large {
    width: 1.25rem;
    height: 1.25rem;
    display: block;
}

.small-shield i svg {
    width: 100%;
    height: 100%;
}

.standard-text{
    @apply text-olive-900 dark:text-olive-100
}
.light-text{
    @apply text-olive-400 dark:text-olive-600;
    @apply fill-olive-400 dark:fill-olive-600;
}
.opposite-text {
    @apply dark:text-olive-900 text-olive-100
}
.hover-standard-text{
    @apply hover:text-olive-900 dark:hover:text-olive-100
}
.hover-opposite-text {
    @apply dark:hover:text-olive-900 hover:text-olive-100
}
.active-standard-text{
    @apply active:text-olive-900 dark:active:text-olive-100
}
.active-opposite-text{
    @apply dark:active:text-olive-900 active:text-olive-100
}