@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

// Roboto Body Type Font
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;700&family=Inter:wght@300;400;500;600;700;900&display=swap');

@import "./base/base.scss";
@import "./base/scrollbars.scss";
@import "./base/typography.scss";
@import "./base/links.scss";
@import "./base/utilities.scss";
@import "./base/colours.scss";


@import "./components/buttons.scss";
@import "./components/footer.scss";
@import "./components/forms.scss";
@import "./components/header.scss";
@import "./components/navigation.scss";
@import "./components/tables.scss";
@import "./components/modals.scss";
@import "./components/progressbar.scss";
@import "./components/openai.scss";


// jm overrides here

.animated-svg svg {
    stroke-dasharray: 60;
    stroke-dashoffset: -60;
    animation: dash 2s linear forwards;
}

@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}