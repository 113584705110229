.field-label {
    @apply block text-gray-900;
}

.label-text {
    @apply leading-none block mb-2 font-medium;
}

.input-field {
    // @apply ring-0 px-4 py-3 border rounded w-full transition-all;
    // @apply placeholder-olive-900 standard-text border-olive-900;
    // @apply dark:placeholder-olive-100 dark:text-white dark:border-olive-100;
    // @apply focus:not-italic focus:outline-none focus:border-cyan-700;
    // @apply hover:border-cyan-700;
    // @apply active:border-cyan-700 ;
    // @apply dark:bg-olive-800 dark:focus:text-white;

    @apply standard-text ring-0 placeholder-gray-500 text-gray-600 w-full focus:not-italic px-4 py-3 border-b border-gray-300 hover:border-primaryhighlight active:border-b-brandactive transition-all focus:outline-none focus:border-b-brandactive;

}

.input-field-small {
    @apply standard-text ring-0 placeholder-gray-500 text-gray-600 w-full focus:not-italic px-3 py-1 border-b border-gray-300 hover:border-primaryhighlight active:border-brandactive transition-all focus:outline-none focus:border-brandactive;
}

.input-field-2fa {
    @apply standard-text ring-0 h-16 placeholder-gray-500 text-gray-600 rounded focus:not-italic text-center border-b border-gray-300 hover:border-primaryhighlight active:border-brandactive transition-all focus:outline-none focus:border-brandactive;
}
.input-field-2fa-container{
    @apply standard-text grid grid-cols-6 gap-2 sm:w-96 text-center mx-auto;
}

.dropdown-field {
    @apply form-select text-gray-700 border-gray-300 px-4 py-3 rounded w-full hover:border-primaryhighlight hover:cursor-pointer focus:ring-brandactive focus:border-brandactive transition-all;
    &:disabled{
        @apply bg-gray-200;
        pointer-events: none;
    }
}

label {
    @apply hover:cursor-pointer;
}

input[type=file]::-webkit-file-upload-button,
input[type=file]::file-selector-button {
    @apply px-4 py-3 rounded-tl rounded-bl border-none inline-block text-center font-medium transition duration-150 ease-in-out bg-olive-900 opposite-text hover:bg-brandactive active:bg-primaryhighlight active:text-brandactive focus:ring-2 focus:ring-inset focus:ring-brandactive hover:cursor-pointer;
    margin-inline-start: -1rem;
    margin-inline-end: 1rem;
}

.shadowdetect-fake-table input, .shadowdetect-fake-table select{
    @apply input-field-small w-full dark:bg-olive-800 dark:text-white
}
.shadowdetect-devicebox input, .shadowdetect-devicebox select{
    @apply input-field-small w-full dark:bg-olive-800 dark:text-white
}
.shadowdetect-whitebox input, .shadowdetect-whitebox select{
    @apply input-field-small w-full dark:bg-olive-800 dark:text-white
}

input[type=range]::-webkit-slider-thumb {
    pointer-events: all;
    width: 24px;
    height: 24px;
    -webkit-appearance: none;

    /* @apply w-6 h-6 appearance-none pointer-events-auto; */
}