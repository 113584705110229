// html,
// body {
//     height: 100%;
//     overflow-x: hidden;
// }

body {
    font-family: 'Inter', sans-serif;
    @apply gradient-light-bg
}

#__next,
#__next .motion-animate-wrapper:first-child,
#__next .motion-animate-wrapper:first-child .wrapper:first-child {
    min-height: 100vh;
    // min-width:100vw;
}

@media (max-width: 1023px) {
    iframe {
        max-width: 100%;
    }
}

.max-h-0 {
    max-height: 0;
}

.whitespace-no-wrap {
    white-space: nowrap;
}

select.appearance-none {
    appearance: auto;
}